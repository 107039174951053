.learn-ai {
    padding: 100px 0;
    width: auto;
    max-width: 880px;
    min-width: 480px;
    margin: 0 auto;
    .banner {
        background: #21144d no-repeat bottom/cover
            url('https://staticcdn.boyuai.com/user-assets/358/2NAGXnoyQSPyymGpswqdt5/hrl-banner.png');
        padding: 100px 0;
        text-align: center;
        color: #f3f3f3;
        font-size: 18px;
        h1 {
          color: #f3f3f3;
          font-size: 48px;
          font-weight: 600;
          line-height: 56px;
        }
    }
    .intro {
      width: 100%;
      text-align: center;
      img {
        width: 100%;
      }
    }

    .links {
      margin-top: 32px;
      width: 100%;
      .course-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 25px 0;
        justify-content: center;

        .course-box {
          width: 230px;
          height: 270px;
          padding: 0 12px;
          margin: 0 48px 24px;
          background-color: #fff;
          box-shadow: 4px 4px 33px rgba(0,0,0,.15);
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: transform .3s;

          &:hover {
            transform: translate(-4px, -4px);
          }

          .course-img {
            width: 230px;
            margin: 0 -12px;
            img {
              width: 100%;
            }
          }

          .course-title {
            margin-top: 8px;
            color: #333;
          }

          .course-intro {
            color: #999;
            font-size: 12px;
          }
          
          .course-link {
            text-align: right;
            color: #2394df;
            font-size: 12px;
          }
        }
      }
    }

    .contact {
      margin: 20px auto 0;
      text-align: center;
      color: #999;    
      font-size: 12px; 
      // line-height: 24px;
      max-width: 800px;
      
      @media (max-width: 768px) {
        font-size: 12px;   
        padding: 0 20px;   
        margin: 30px auto; 
      }
    }
}

@media (max-width: 768px) {
  .learn-ai {
    padding: 0 0 40px;
    min-width: unset;
    width: 100%;

    .banner {
      padding: 120px 20px;
      background-position: center;
      
      h1 {
        font-size: 32px;
        line-height: 1.2;
      }
    }
    h2 {
      font-size: 20px;
      text-align: center;
    }
    .links {
      .course-wrapper {
        margin: 15px 0;
        
        .course-box {
          width: 100%;
          height: auto;
          margin: 0 0 16px;
          padding: 12px;
          
          .course-img {
            margin: auto;
            width: 100%;

            img {
              width: 100%;
            }
          }
          
          .course-title {
            font-size: 16px;
          }
        }
      }
    }
  }
}